import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'
import { USDC_UNISEPOLIA } from './common'

export const uniSepoliaTokens = {
  weth: WETH9[ChainId.UNISEPOLIA],
  usdc: USDC_UNISEPOLIA,
  foc: new Token(
    ChainId.UNISEPOLIA,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  )
}

