import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

// export const Baobabprodnode = 'https://public-en-baobab.klaytn.net/'


// export const Saigonprodnode = 'https://saigon-testnet.roninchain.com/rpc'


export const uniSepolianode = 'https://sepolia.unichain.org/'

// export const baobabRpcProvider = new StaticJsonRpcProvider(Baobabprodnode)

// export const saigonRpcProvider = new StaticJsonRpcProvider(Saigonprodnode)


export const uniSepoliaRpcProvider = new StaticJsonRpcProvider(uniSepolianode)

export default null
