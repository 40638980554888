import { MenuItemsType, SwapIcon, SwapFillIcon, EarnFillIcon, EarnIcon, ShopIcon, EventIcon, MoreIcon, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Bridge'),
      // href: 'https://freeswap.org/',
      icon: ShopIcon,
      fillIcon: ShopIcon,
      image: '/images/decorations/pe2.png',
      items: [
        {
          label: 'Unichain Secure Faucets',
          href: 'https://docs.unichain.org/docs/tools/faucets',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        // {
        //   label: 'Core',
        //   href: 'https://bridge.coredao.org/bridge',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ],
    }, 
    {
      label: t('About'),
      // href: 'https://freeswap.org/',
      icon: ShopIcon,
      fillIcon: ShopIcon,
      image: '/images/decorations/pe2.png',
      items: [
        {
          label: t('About'),
          href: '/about',
          // type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        // {
        //   label: 'Core',
        //   href: 'https://bridge.coredao.org/bridge',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ],
    }, 
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
