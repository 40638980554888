import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'

export const baseTokens = {
  weth: WETH9[ChainId.BASE],
  foc: new Token(
    ChainId.BASE,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  )
}
