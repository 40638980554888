import _define_property from "@swc/helpers/src/_define_property.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        ";\n  text-shadow: ",
        ";\n  font-weight: ",
        ";\n  line-height: 1.1;\n  font-family: ",
        ";\n\n  ",
        " {\n    font-size: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Text from "../Text/Text";
import { tags, scales } from "./types";
var _obj;
var style = (_obj = {}, _define_property(_obj, scales.MD, {
    fontSize: "20px",
    fontSizeLg: "20px",
    textShadow: "none",
    fontFamily: "Kanit",
    fontWeignt: "600"
}), _define_property(_obj, scales.LG, {
    fontSize: "24px",
    fontSizeLg: "24px",
    textShadow: "none",
    fontFamily: "Kanit",
    fontWeignt: "600"
}), _define_property(_obj, scales.XL, {
    fontSize: "40px",
    fontSizeLg: "40px",
    textShadow: "none",
    fontFamily: "Kanit",
    fontWeignt: "600"
}), _define_property(_obj, scales.XXL, {
    fontSize: "48px",
    fontSizeLg: "78px",
    textShadow: "1px 1px 5px rgba(0, 0, 0, 0.7)",
    fontFamily: "Kanit",
    fontWeignt: "500"
}), _define_property(_obj, scales.XXXL, {
    fontSize: "78px",
    fontSizeLg: "110px",
    textShadow: "1px 1px 5px rgba(0, 0, 0, 0.7)",
    fontFamily: "Kanit",
    fontWeignt: "600"
}), _obj);
var Heading = styled(Text).attrs({
    bold: true
}).withConfig({
    componentId: "sc-71d275be-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSize;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].textShadow;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontWeignt;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontFamily;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.lg;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSizeLg;
});
Heading.defaultProps = {
    as: tags.H2
};
export default Heading;
